import React from 'react'
import poster1 from '../assets/service-poster-1.jpg'
import poster2 from '../assets/service-poster-2.jpg'
import poster3 from '../assets/service-poster-3.jpg'
import poster4 from '../assets/service-poster-4.jpg'
import poster5 from '../assets/service-poster-5.jpg'
import poster6 from '../assets/service-poster-6.jpg'
import { Link } from 'react-router-dom'
import Service from './snippets/Service'

const servicesList = [
  {
    link: 'strategy',
    header: 'Стратегія',
    poster: poster1,
    tag: '01.',
    text:
      'Бізнес-модель / Розробка ціннісної пропозиції (Value Proposition) /\
        Бренд стратегія та позиціонування / Комунікаційна стратегія / Digital \
        стратегія / Стратегічні сесії',
    fulltexts: [
      {
        h3: 'Бізнес-модель',
        p:
          'Щоб витримати конкуренцію, випускаючи на ринок продукт, вам необхідний цілісний план дій, який допоможе здобути цільових клієнтів, згенерувати продажі та отримати позитивний баланс витрат та прибутків.',
      },
      {
        h3: 'Розробка ціннісної пропозиції (Value Proposition)',
        p:
          'Ціннісна пропозиція є основою для позиціонування бренду. Потужна ціннісна пропозиція, що задовольняє потреби великої групи споживачів, є передумовою довгострокового домінування бренду на ринку.',
      },
      {
        h3: 'Бренд стратегія та позиціонування',
        p:
          'Бренд стратегія та позиціонування дають можливість створити найбільш привабливий для вашої аудиторії образ бренду або продукту. Проаналізувавши вашу категорію, споживачів та їхні потреби, ми втілимо ваші цінності, конкурентні переваги та сильні сторони в цілісному сприйнятті бренду на ринку.',
      },
      {
        h3: 'Комунікаційна стратегія',
        p:
          'Голос вашого бізнесу повинен бути чітким та впізнаваним, а ваше повідомлення – зрозумілим. Щоб ваша комунікація резонувала в аудиторії і до неї дослухалися, ми розробляємо та впроваджуємо ефективні комунікаційні стратегії, що включають повідомлення та конкретні точки контакту, що допомагають досягти бізнес-цілей.',
      },
      {
        h3: 'Digital стратегія',
        p:
          'Присутність бренду в інфополі цільвої аудиторії є обов’язковою для розвитку бізнесу. Ми проаналізуємо вашу цифрову присутність та конкурентне середовище, щоб визначити точки росту і сформувати план роботи над комунікацією бренду в інтернеті.',
      },
      {
        h3: 'Стратегічні сесії',
        p:
          'Стратегічна сесія дає можливість подивитися на свій бізнес під новим кутом, відповісти на болючі питання та стати на шлях розвитку. Ми проводимо такі сесії у форматі воркшопу, під час якого працюємо разом із вашою робочою групою: генеруємо гіпотези, аналізуємо результати досліджень та розробляємо ціннісну пропозицію вашого бренду чи продукту.',
      },
    ],
  },
  {
    link: 'creatives',
    header: 'Креатив та реклама',
    poster: poster2,
    tag: '02.',
    text: 'Розробка рекламного креативу',
    fulltexts: [
      {
        h3: 'Розробка рекламного креативу',
        p:
          'Ми розробляємо та виготовляємо яскраві та ефективні комунікаційні матеріали (банери, відеоролики, промо-сторінки), що привертають увагу та якісно доносять комунікаційне повідомлення вашій аудиторії. Повністю супроводжуємо реалізацію вашої рекламної кампанії від ідеї до запуску і фінальних результатів.',
      },
    ],
  },
  {
    link: 'branding',
    header: 'Брендинг',
    poster: poster3,
    tag: '03.',
    text: 'Айдентика / Неймінг / Розробка Tone of Voice',
    fulltexts: [
      {
        h3: 'Айдентика',
        p:
          'Ми допоможемо бренду втілити свої цінності через власний стиль — розробимо логотип та візуальну стилістику бренду. Айдентика має бути цілісною, зручною у використанні та такою, що запам’ятовується, виділяється та якнайдовше не втрачає актуальності. Розробка айдентики включає створення брендбуку та стайлгайду з повною інформацією про візуальний стиль бренду та правила його використання.',
      },
      {
        h3: 'Неймінг',
        p:
          'Розробимо яскраву та водночас зручну у використанні назву для нового бренду або продукту чи послуги, що вже існує на ринку.',
      },
      {
        h3: 'Розробка Tone of Voice',
        p:
          'ToV визначає, як звучатиме у голові клієнта ваш бренд, який настрій викликатиме, яким характером володітиме та як про нього згадуватимуть. Розробка голосу бренду — це створення чіткої інструкції та правил комунікації з клієнтами в онлайні та офлайні, do’s and don’ts та скрипти повідомлень для різних ситуацій.',
      },
    ],
  },
  {
    link: 'media',
    header: 'Медіа',
    poster: poster4,
    tag: '04.',
    text:
      'Медіапланування та управління медійними кампаніями / Web-аналітика / SEO-оптимізація / Контекстна і таргетована реклама',
    fulltexts: [
      {
        h3: 'Медіапланування та управління медійними кампаніями',
        p:
          'Допоможемо визначити ключові задачі іміджевої комунікації та ефективний набір медійних інструментів (банери, відеореклама), що допоможуть якісно охопити максимум цільової аудиторії за мінімальний бюджет.',
      },
      {
        h3: 'Web-аналітика',
        p:
          'Налаштуємо систему відслідковування та аналізу поведінки клієнтів на вашому сайті. Виміряємо результати рекламних кампаній, визначимо ефективні інструменти та оптимізуємо бюджет для отримання максимальних результатів.',
      },
      {
        h3: 'SEO-оптимізація',
        p:
          'Пропрацюємо внутрішню структуру вашого сайту, щоб отримати кращі позиції в органічній видачі, враховуючи пошукові запити вашої цільової аудиторії.',
      },
      {
        h3: 'Контекстна і таргетована реклама',
        p:
          'Планування та налаштування контекстної і таргетованої реклами: для збільшення трафіку та генерації продажів, замовлень, встановлень додатку, підписок та інших цілей перформанс-маркетингу.',
      },
    ],
  },
  {
    link: 'smm',
    header: 'SMM та контент',
    poster: poster5,
    tag: '05.',
    text: 'Соціальні мережі',
    fulltexts: [
      {
        h3: 'Соціальні мережі',
        p:
          'Розробляємо стратегію присутності вашого бізнесу в соціальних мережах та виготовляємо необхідний контент для кожної з платформ. Визначаємо правила комунікації, стилістику, тональність та загальні вимоги до матеріалів та повідомлень, що використовуються в SMM.',
      },
    ],
  },
  {
    link: 'outsourcing',
    header: 'Маркетинг-аутсорс',
    poster: poster6,
    tag: '06.',
    text:
      'Стратегія виходу на ринок / Розробка комунікаційної стратегії / Лідогенерація та продажі онлайн / Інтеграція бренду в кіберспорт та геймінг',
    fulltexts: [],
  },
]

const FullService = ({ link }) => {
  const currentService = servicesList.find((service) => service.link === link)
  return (
    <>
      <section id="services">
        <div className="container-p flex flex-col pb-16">
          <img
            className="mx-auto w-full md:my-16 max-w-4xl"
            alt={currentService.header}
            src={currentService.poster}
          ></img>
          <div className="md:flex items-start justify-center mt-8">
            <Link to="/">Головна /</Link>
            <h2 className="text-center mt-4 md:mt-0 md:ml-4">
              {currentService.header}
            </h2>
          </div>
          <div className="flex flex-col mt-8 px-4 xxs:px-8 xs:px-12 sm:px-20 md:px-32 max-w-4xl mx-auto">
            {currentService.fulltexts.map((paragraph, index) => (
              <>
                <h4 className="mt-8" key={index}>
                  {paragraph.h3}
                </h4>
                <p className="mt-2" key={index + 1}>
                  {paragraph.p}
                </p>
              </>
            ))}
            <div className="h-0 border-b-2 border-slate-100 pt-4"></div>
          </div>
        </div>
      </section>
      <section id="services">
        <div className="container-p flex flex-col items-center">
          <div className="flex flex-wrap justify-center mx-auto">
            {servicesList
              .filter((service) => service.link !== currentService.link)
              .map((service, index) => (
                <Service
                  link={service.link}
                  header={service.header}
                  poster={service.poster}
                  tag={service.tag}
                  text={service.text}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default FullService
